import { useEffect, useRef, useState, forwardRef, ForwardRefRenderFunction, useImperativeHandle, ReactElement, useCallback, RefObject } from 'react';
import { FormConfig } from '../../../models/Form';
import { EdgeDetectUtils } from '../../../utils/EdgeDetectUtils';
import { nextTick } from '../../../utils/ReactUtils';
import { Action, FormBuilderForm, FormBuilderPlaceholder, PlaceholderTarget } from '../../form-builder/FormBuilderTypes';
import RootPortal from '../RootPortal';
import { ActionPropertiesPlaceholderMenu, SelectSectionPlaceholderMenu } from './PlaceholderSelectMenuPage';
import { useTranslation } from 'react-i18next';
import { EventSystem } from '../../../events/EventSystem';
import { Translations } from '../../../models/Translation';

export type MenuAction = { targetId: string; target: PlaceholderTarget; fieldId?: string; translations?: Translations };

type Props = {
  form: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
  menuAction: MenuAction;
  targetRef?: RefObject<HTMLDivElement>;
  insertPlaceholder: (placeholder: FormBuilderPlaceholder) => void;
  allowExternalData?: boolean;
};

type Handle = {
  trigger: (placeholderId?: string) => void;
  close: () => void;
  isOpen: boolean;
};

function isDescendant(parent: HTMLElement, child: HTMLElement | null) {
  let node = child;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentElement;
  }

  return false;
}

const PlaceholderSelectMenu: ForwardRefRenderFunction<Handle, Props> = (props, ref) => {
  const { form, insertPlaceholder, menuAction, referencedForms, targetRef, allowExternalData = true } = props;
  const [open, setOpen] = useState(false);
  const selfRef = useRef<HTMLDivElement | null>(null);
  const lastTargetRect = useRef<DOMRect | null>(null);
  const [pageStack, setPageStack] = useState<ReactElement[]>([]);
  const { i18n } = useTranslation();

  const closeMenu = useCallback(() => {
    setOpen(false);
    lastTargetRect.current = null;
  }, []);

  useEffect(
    function closeOnClick() {
      if (!open) {
        setPageStack([]);
        return;
      }

      const handle = (e: MouseEvent) => {
        const popoverRoot = document.getElementById('popover-root');
        if (!popoverRoot) {
          return;
        }

        if (!isDescendant(popoverRoot, e.target as HTMLElement)) {
          closeMenu();
          setTimeout(() => {
            EventSystem.fireEvent('dynamic-data-popup-closed', { action: menuAction, createdPlaceholder: false });
          }, 10);
        }
      };

      document.addEventListener('mouseup', handle);
      return () => document.removeEventListener('mouseup', handle);
    },
    [closeMenu, menuAction, open],
  );

  const goNext = useCallback((next: ReactElement) => {
    nextTick(() => setPageStack((prev) => [...prev, next]));
  }, []);

  const goPrevious = useCallback(() => {
    setPageStack((prev) => prev.slice(0, -1));
  }, []);

  const addPlaceholder = useCallback(
    (placeholder: FormBuilderPlaceholder) => {
      insertPlaceholder(placeholder);
      closeMenu();

      setTimeout(() => {
        EventSystem.fireEvent('dynamic-data-popup-closed', { action: menuAction, createdPlaceholder: true });
      }, 10);
    },
    [closeMenu, menuAction, insertPlaceholder],
  );

  const getInitialStack = useCallback(
    (placeholderId: string | undefined) => {
      if (!placeholderId) {
        return [
          <SelectSectionPlaceholderMenu
            key={0}
            form={form}
            goNext={goNext}
            goPrevious={goPrevious}
            closeMenu={closeMenu}
            insertPlaceholder={addPlaceholder}
            menuAction={menuAction}
            referencedForms={referencedForms}
            previewLanguage={i18n.language}
            allowExternalData={allowExternalData}
          />,
        ];
      }

      const placeholder = form.placeholders?.find((x) => x.placeholder === placeholderId);
      const selfFormAction = form.sections.flatMap((x) => x.actions).find((x) => x.id === placeholder?.referencedActionId);
      let refrencedFormAction: Action | undefined;
      let secondaryDataSourceAction: Action | undefined;
      if (!selfFormAction) {
        refrencedFormAction = (Object.values(referencedForms) as FormBuilderForm[])
          .flatMap((x) => x.sections)
          .flatMap((x) => x.actions)
          .find((x) => x.id === placeholder?.referencedActionId);
        secondaryDataSourceAction = [form, ...(Object.values(referencedForms) as FormBuilderForm[])]
          .flatMap((x) => x.sections)
          .flatMap((x) => x.actions)
          .find((x) => x.id === placeholder?.actionIdContainingAssociation);
      }

      // no default selection can be made
      if (!selfFormAction && !refrencedFormAction && !secondaryDataSourceAction) {
        return [
          <SelectSectionPlaceholderMenu
            key={0}
            form={form}
            goNext={goNext}
            goPrevious={goPrevious}
            closeMenu={closeMenu}
            insertPlaceholder={addPlaceholder}
            menuAction={menuAction}
            referencedForms={referencedForms}
            editingPlaceholderId={placeholderId}
            previewLanguage={i18n.language}
          />,
        ];
      }

      return [
        <ActionPropertiesPlaceholderMenu
          key={0}
          action={(selfFormAction || refrencedFormAction) as Action}
          form={form}
          goNext={goNext}
          goPrevious={goPrevious}
          closeMenu={closeMenu}
          insertPlaceholder={addPlaceholder}
          menuAction={menuAction}
          referencedForms={referencedForms}
          editingPlaceholderId={placeholderId}
          allowExternalData={!selfFormAction}
          secondaryDataSourceAction={secondaryDataSourceAction}
          previewLanguage={i18n.language}
        />,
      ];
    },
    [addPlaceholder, allowExternalData, closeMenu, form, goNext, goPrevious, i18n.language, menuAction, referencedForms],
  );

  useImperativeHandle(
    ref,
    () => ({
      trigger(placeholderId?: string) {
        const stack = getInitialStack(placeholderId);
        if (stack) {
          setOpen(true);
          setPageStack(stack);
          EventSystem.fireEvent('dynamic-data-popup-opened', { action: menuAction });
        }
      },
      close() {
        closeMenu();

        setTimeout(() => {
          open && EventSystem.fireEvent('dynamic-data-popup-closed', { action: menuAction, createdPlaceholder: false });
        }, 10);
      },
      isOpen: open,
    }),
    [closeMenu, getInitialStack, menuAction, open],
  );

  const positionMenu = useCallback(() => {
    setTimeout(() => {
      // use targetRef to position menu, fallback to selection in dom
      let rect = lastTargetRect.current ?? targetRef?.current?.getBoundingClientRect();
      if (!rect) {
        const domSelection = window.getSelection();
        if (!domSelection?.rangeCount) {
          return;
        }

        const domRange = domSelection.getRangeAt(0);
        rect = domRange?.getBoundingClientRect();
      }

      if (!rect || (rect.width === 0 && rect.height === 0)) {
        const selectedElement = document.activeElement as HTMLElement;
        rect = selectedElement.getBoundingClientRect();
      }

      if (!rect || !selfRef.current) {
        return;
      }

      lastTargetRect.current = rect;
      const menuRect = selfRef.current.getBoundingClientRect();
      const dropdownEdges = EdgeDetectUtils.checkEdgesBounding({
        ...rect,
        top: rect.top + rect.height + 10 || 0,
        width: menuRect.width,
        height: menuRect.height,
      });

      if (dropdownEdges.any) {
        if (dropdownEdges.bottom) {
          selfRef.current.style.top = `${rect.top - menuRect.height - 10}px`;
        } else if (dropdownEdges.top) {
          selfRef.current.style.top = `${rect.bottom + menuRect.height}px`;
        }
      } else {
        selfRef.current.style.top = `${rect.top + window.pageYOffset + rect.height + 10}px`;
      }
      let left = rect.left + window.pageXOffset + rect.width / 2 - selfRef.current.clientWidth / 2;
      if (left + selfRef.current.clientWidth > window.innerWidth) {
        left += window.innerWidth - (left + selfRef.current.clientWidth);
      }
      selfRef.current.style.left = `${left}px`;
    }, 16);
  }, [targetRef]);

  useEffect(
    function positionMenuOnOpen() {
      open && positionMenu();
    },
    [positionMenu, open],
  );

  if (!open) {
    return null;
  }
  return (
    <RootPortal elementId="popover-root">
      <div className="z-left-nav absolute w-[350px] overflow-hidden rounded-[5px] bg-white shadow-xl" ref={selfRef}>
        {pageStack[pageStack.length - 1]}
      </div>
    </RootPortal>
  );
};

export default forwardRef(PlaceholderSelectMenu);
