/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentProps, FC } from 'react';
import FroalaInput, { FroalaInputProps } from '../form-control/FroalaInput';
import InputWithDynamicData from '../form-control/InputWithDynamicData';
import ContentEditableDivInput from '../form-control/ContentEditableDivInput';
import { useTranslation } from 'react-i18next';

const PlaceholderTextBox: FC<ComponentProps<typeof InputWithDynamicData> & FroalaInputProps & { enableRichText?: boolean }> = (props) => {
  const { enableRichText } = props;
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="relative">
      <InputWithDynamicData key={language} {...props} Component={(enableRichText ? FroalaInput : ContentEditableDivInput) as any} />
    </div>
  );
};
export default PlaceholderTextBox;
