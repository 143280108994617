/* eslint-disable @typescript-eslint/no-explicit-any */
import { DownloadPdfPreferences } from '../../models/DownloadPdfPreferences';
import { FormConfig, PeriodicReviewConfig, PlaceholderFormat } from '../../models/Form';
import { FormType } from '../../models/FormTypes';
import { Translations } from '../../models/Translation';
import { ActionTypeNames } from '../form/ActionTypes';

export enum BuilderDroppableTypes {
  Sidebar = 'sidebar',
  Section = 'section',
  Action = 'action',
}

export type FormBuilderForm = {
  id: string;
  code: string;
  version: number;
  isFavourite?: boolean;
  iconCode: string;
  translations: Translations;
  archivedUtc?: string | null;
  type?: (typeof FormType)[keyof typeof FormType];
  requiresApproval?: boolean;
  requiresApprovalSignature?: boolean;
  requiresValidation?: boolean;
  requiresValidationSignature?: boolean;
  requiresOwnerAcknowledgement?: boolean;
  sections: Section[];
  placeholders?: FormBuilderPlaceholder[];
  periodicReviewConfig?: PeriodicReviewConfig | null;
  templateModuleId?: string;
  templateModuleSectionId?: string;
  downloadPdfPreferences?: DownloadPdfPreferences;
};

export type FormBuilderPlaceholder = {
  actionIdContainingAssociation: string | null;
  referencedActionId: string;
  targetId: string;
  target: PlaceholderTarget;
  translations: Translations;
  friendlyName: string;
  dataFormat: PlaceholderFormat;
  placeholder: string;
  placeholderId?: string;
};

export enum PlaceholderTarget {
  Action = 0,
  Form = 1,
}

export const GraphQLPlaceholderTargetToEnum: Record<string, (typeof PlaceholderTarget)[keyof typeof PlaceholderTarget]> = {
  ACTION: PlaceholderTarget.Action,
  FORM: PlaceholderTarget.Form,
};

export type StepTrigger = {
  stepId: string;
  condition: string;
};

export type Section = {
  id: string;
  visible?: string;
  isPublic?: boolean;
  requiresApproval?: boolean;
  translations: Translations<'title'>;
  triggerSteps: StepTrigger[];
  actions: Action[];
  isDraft?: boolean;
  isHiddenFromExport?: boolean;
  number?: string; // for display
};

export type Action = {
  id: string;
  type: ActionTypeNames;
  data?: any;
  noninteractive?: boolean;
  visible?: string;
  required?: string;
  translations?: Translations;
  riskEnabled?: boolean;
  riskConditions?: string[];
  riskNoMatch?: string | undefined;
  includeInFormBuilder?: boolean;
  shownInResourceOverview?: boolean;
  previewHidden?: boolean;
  shownInOverview?: boolean;

  // DO NOT SET. This is only here to be able to remove its value on-save
  response?: any;
  number?: string; // for display
};

export type EditorProps = {
  action: Action;
  patchTranslations: (translations: Translations) => void;
  patchAction: (action: any) => void;
  patchData: (data: any) => void;
  patchForm: (data: Partial<FormBuilderForm>) => void;
  form: FormBuilderForm;
  invalidStates: Record<string, string[]>;
  referencedForms: Record<string, FormConfig>;
  builderBaseUrl: string;
};

export enum ConditionalAndOr {
  NONE = '',
  AND = 'and',
  OR = 'or',
}

export enum EditorType {
  FORM = 'form',
  SECTION = 'section',
  ACTION = 'action',
}

export type ActionPropertiesProps = {
  action: Action;
  patchAction: (data: any) => void;
  patchForm: (data: Partial<FormBuilderForm>) => void;
  form: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
  invalidStates: Record<string, string[]>;
  builderBaseUrl: string;
};

export type SectionPropertiesProps = {
  form: FormBuilderForm;
  section: Section;
  patchSection: (data: Partial<Section>) => void;
};

export type FormPropertiesProps = {
  form: FormBuilderForm;
  patchFormConfig: (formConfig: Partial<FormBuilderForm>) => void;
  loadedType?: (typeof FormType)[keyof typeof FormType];
  originalForm?: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
};

export const FieldLabelLengthLimit = 25;
