import { FC, useMemo } from 'react';
import { SupportedLanguage, supportedLanguages } from '../../types/Languages';
import { useTranslation } from 'react-i18next';

type Props = {
  selected: SupportedLanguage;
  errorState?: boolean;
  onChange: (value: SupportedLanguage) => void;
};

const TranslatableInputButtons: FC<Props> = (props) => {
  const { selected, errorState, onChange } = props;
  const {
    i18n: { language: userLanguage },
  } = useTranslation();

  const options = useMemo(
    () =>
      // Put the user's language first
      [supportedLanguages.find((x) => x.id === userLanguage)!.id].concat(supportedLanguages.map((x) => x.value).filter((x) => x !== userLanguage)),
    [userLanguage],
  );

  return (
    <>
      {options.map((code, i) => (
        <div key={code} className="flex items-center gap-1">
          <div
            className={`${
              selected === code
                ? `${errorState ? 'bg-accent-2' : 'bg-primary-1'} cursor-default font-medium text-white`
                : `${errorState ? 'text-accent-2' : 'text-color-on-first-primary-1'} cursor-pointer`
            } text-dpm-12 rounded-lg px-[0.4rem] py-[0.2rem] uppercase`}
            onClick={() => onChange(code)}
          >
            {code}
          </div>
          <div className="text-color-on-first-primary-1">{i < options.length - 1 ? ' | ' : ''}</div>
        </div>
      ))}
    </>
  );
};

export default TranslatableInputButtons;
