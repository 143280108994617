/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, ComponentRef, FC, FocusEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FroalaInput, { FroalaInputProps } from './FroalaInput';
import { FormBuilderForm, FormBuilderPlaceholder } from '../../form-builder/FormBuilderTypes';
import { FormConfig } from '../../../models/Form';
import PlaceholderSelectMenu, { MenuAction } from '../placeholder/PlaceholderSelectMenu';
import { useTranslation } from 'react-i18next';
import { parseHtmlToPlaceholders, parsePlaceholdersToHtml } from '../../../utils/PlaceholderUtils';
import ObjectUtils from '../../../utils/ObjectUtils';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';
import { createPortal } from 'react-dom';
import { answerPlaceholderWordInterpolator } from '../placeholder/AnswerPlaceholderWord';
import Tooltip from '../Tooltip';

type RequiredInputProps = {
  onKeyDown?: (e: KeyboardEvent<any>) => void;
  onBlur?: (e: FocusEvent<any>) => void;
  onFocus?: (e: FocusEvent<any>) => void;
  enableRichText?: boolean;
};

export type DynamicDataInterfaceHandle = {
  insertPlaceholder?: (placeholder: string) => void;
  savePosition?: () => void;
  removePlaceholder?: (placeholder: string) => void;
};

type Props<TInputProps extends RequiredInputProps = FroalaInputProps> = TInputProps & {
  Component?: FC<TInputProps>;
  enableDynamicData?: boolean;
  allowExternalDynamicData?: boolean;
  form: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
  action: MenuAction;
  onPlaceholdersChange: (value: FormBuilderPlaceholder[]) => void;
  onRemovedPlaceholder?: (placeholder: string) => void;
};

const InputWithDynamicData = <TInputProps extends RequiredInputProps = FroalaInputProps>(props: Props<TInputProps>) => {
  const {
    Component = FroalaInput as unknown as FC<TInputProps>,
    enableDynamicData = true,
    allowExternalDynamicData,
    form,
    referencedForms,
    action,
    onPlaceholdersChange,
    onRemovedPlaceholder,
  } = props;
  const [hasFocus, setHasFocus] = useState(false);
  const { t } = useTranslation(['form-builder', 'form']);
  const menuRef = useRef<ComponentRef<typeof PlaceholderSelectMenu>>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const targetMenuRef = useRef<HTMLDivElement | null>(null);
  const componentRef = useRef<DynamicDataInterfaceHandle>(null);
  const { placeholders: formRendererPlaceholders, clientFormId } = useFormRendererInfo();

  const onKeyDownInternal = useCallback(
    (e: KeyboardEvent) => {
      if (enableDynamicData && e.key === '/') {
        if (!componentRef.current?.savePosition) console.warn("Input doesn't implement `savePosition` method");
        componentRef.current?.savePosition?.();

        menuRef.current?.trigger();
        return;
      }

      props.onKeyDown?.(e);
    },
    [enableDynamicData, props],
  );

  const onBlurInternal = useCallback(
    (e: FocusEvent<any>) => {
      setHasFocus(false);
      props.onBlur?.(e);
    },
    [props],
  );

  const onFocusInternal = useCallback(
    (e: FocusEvent<any>) => {
      setHasFocus(true);
      props.onFocus?.(e);
    },
    [props],
  );

  const componentProps = useMemo(() => {
    const result = { ...props };

    // Input
    if ('value' in result) result.value = parsePlaceholdersToHtml(result.value as string, form, !!clientFormId, props.enableRichText);
    if ('initialValue' in result)
      result.initialValue = parsePlaceholdersToHtml(result.initialValue as string, form, !!clientFormId, props.enableRichText);
    if ('translations' in result) {
      const translationsResult = ObjectUtils.DeepClone(result.translations ?? {});
      for (const langKey of Object.keys(translationsResult)) {
        (translationsResult as any)[langKey] ??= {};
        for (const tKey of Object.keys((result.translations as any)[langKey])) {
          (translationsResult as any)[langKey][tKey] = parsePlaceholdersToHtml(
            (translationsResult as any)[langKey][tKey],
            form,
            !!clientFormId,
            props.enableRichText,
          );
        }
      }
      result.translations = translationsResult;
    }

    // Output
    if ('onChange' in result) {
      result.onChange = (e: ChangeEvent<HTMLInputElement> | string) => {
        const eIsString = typeof e === 'string';
        const text = eIsString ? e : e.target.value;
        const result = parseHtmlToPlaceholders(text);
        (props as any)?.onChange?.(eIsString ? result : { target: { value: result } });
      };
    }
    if ('onTranslationsChange' in result) {
      result.onTranslationsChange = (translations: Record<string, Record<string, string>>) => {
        const translationsResult = { ...translations };
        for (const langKey of Object.keys(translationsResult)) {
          for (const tKey of Object.keys(translationsResult[langKey])) {
            translationsResult[langKey][tKey] = parseHtmlToPlaceholders(translationsResult[langKey][tKey]);
          }
        }

        (props as any)?.onTranslationsChange?.(translationsResult);
      };
    }
    if ('onTextChange' in result) {
      result.onTextChange = (text: string) => {
        (props as any)?.onTextChange?.(parseHtmlToPlaceholders(text));
      };
    }

    return result;
  }, [form, props, clientFormId]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const dataTag = target.closest('[data-placeholder]:not([data-is-answer])') as HTMLDivElement;
      const dataTagRemove = target.closest('[data-placeholder-delete]:not([data-is-answer])');

      if (e.type === 'dblclick' && dataTag && wrapperRef.current?.contains(dataTag)) {
        e.preventDefault();

        targetMenuRef.current = dataTag;
        setTimeout(() => (targetMenuRef.current = null), 250);

        const placeholder = dataTag.getAttribute('data-placeholder')!;
        menuRef.current?.trigger('${{' + placeholder + '}}');
      } else if (e.type === 'click' && dataTagRemove && wrapperRef.current?.contains(dataTagRemove)) {
        const placeholder = dataTagRemove.getAttribute('data-placeholder-delete')!;
        onRemovedPlaceholder?.('${{' + placeholder + '}}');
        onPlaceholdersChange(form.placeholders?.filter((x) => x.placeholder !== '${{' + placeholder + '}}') ?? []);

        setTimeout(() => {
          if (!componentRef.current?.removePlaceholder) console.warn('Input does not implement `removePlaceholder` method');
          componentRef.current?.removePlaceholder?.(placeholder);
        }, 10);
      }
    };

    document.addEventListener('click', handler);
    document.addEventListener('dblclick', handler);
    return () => {
      document.removeEventListener('click', handler);
      document.removeEventListener('dblclick', handler);
    };
  }, [form.placeholders, onPlaceholdersChange, onRemovedPlaceholder]);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const mutationObserver = new MutationObserver((mutations) => {
      if (mutations[0].removedNodes.length > 0) {
        const removedNodes = Array.from(mutations[0].removedNodes);

        // If any of the removed nodes is not an element or doesn't have a dataset, ignore it, since
        // we removed 1) Not a placeholder, 2) More than just a placeholder
        if (removedNodes.some((x) => x.nodeType !== Node.ELEMENT_NODE || !(x as HTMLElement)?.dataset?.placeholder)) return;

        const removedPlaceholders = removedNodes.map((x) => '${{' + (x as HTMLElement).dataset.placeholder! + '}}');
        if (removedPlaceholders.length === 0) return;

        removedPlaceholders.forEach((placeholder) => {
          if (!componentRef.current?.removePlaceholder) console.warn('Input does not implement `removePlaceholder` method');
          componentRef.current?.removePlaceholder?.(placeholder);
          onRemovedPlaceholder?.(placeholder);
        });
        setTimeout(() => {
          onPlaceholdersChange(form.placeholders?.filter((x) => !removedPlaceholders.includes(x.placeholder)) ?? []);
        }, 250);
      }
    });
    mutationObserver.observe(wrapperRef.current, {
      childList: true,
      subtree: true,
    });

    return () => {
      mutationObserver.disconnect();
    };
  }, [form.placeholders, onPlaceholdersChange, onRemovedPlaceholder]);

  // used to refresh the portals on first render/load
  const [portalsRefreshCounter, setPortalsRefreshCounter] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setPortalsRefreshCounter((prev) => prev + 1);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const portals = useMemo(() => {
    if (!wrapperRef.current) return;

    // use the var to be able to put it in the dependency array
    if (portalsRefreshCounter < -100) return;

    const toHydrate = wrapperRef.current.querySelectorAll('[data-placeholder][data-is-answer]');
    return Array.from(toHydrate).map((element) => {
      const placeholder = element.getAttribute('data-placeholder')!;
      const result = interpolateActionData('${{' + placeholder + '}}', formRendererPlaceholders, answerPlaceholderWordInterpolator);

      const loader = element.querySelector('[data-loader]');
      if (loader) {
        loader.remove();
      }

      return createPortal(
        <Tooltip text={t('form:dynamic-data-in-answer')}>
          {(tooltip) => (
            <span {...tooltip} contentEditable={false}>
              {result}
            </span>
          )}
        </Tooltip>,
        element,
        placeholder,
      );
    });
  }, [formRendererPlaceholders, portalsRefreshCounter, t]);

  // Bug? Sometimes the above portal loses connection to the rendered div, and re-adds the content instead of replacing it
  // This useEffect fixes that by removing all the extra elements that are added
  useEffect(() => {
    if (!wrapperRef.current) return;
    const toWatch = wrapperRef.current.querySelectorAll('[data-placeholder][data-is-answer]');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          const element = (node as HTMLElement).parentElement as HTMLElement;

          // If element has 2+ children, start removing them, from the top, except the first element (the &nbsp;)
          if (element.children.length > 2) {
            do {
              const child = element.children[1];
              element.removeChild(child);
            } while (element.children.length > 2);
          }
        });
      });
    });

    toWatch.forEach((element) => {
      observer.observe(element, {
        childList: true,
      });
    });

    return () => {
      observer.disconnect();
    };
  }, [hasFocus]);

  return (
    <div ref={wrapperRef}>
      <Component {...componentProps} onKeyDown={onKeyDownInternal} onBlur={onBlurInternal} onFocus={onFocusInternal} ref={componentRef} />
      {portals}

      {enableDynamicData && (
        <p className={`text-dpm-12 px-1 ${hasFocus ? '' : 'invisible'}`} data-cy="placeholder-hint">
          {t('placeholder-menu.help')}
        </p>
      )}

      {enableDynamicData && (
        <PlaceholderSelectMenu
          allowExternalData={allowExternalDynamicData}
          targetRef={targetMenuRef}
          ref={menuRef}
          form={form}
          referencedForms={referencedForms}
          menuAction={action}
          insertPlaceholder={(placeholder) => {
            const newPlaceholders = [...(form.placeholders ?? []).filter((x) => x.placeholder !== placeholder.placeholder), placeholder];
            onPlaceholdersChange(newPlaceholders);

            const updatedForm: FormBuilderForm = {
              ...form,
              placeholders: newPlaceholders,
            };

            setTimeout(() => {
              if (!componentRef.current?.insertPlaceholder) console.warn("Input doesn't implement `insertPlaceholder` method");
              componentRef.current?.insertPlaceholder?.(
                parsePlaceholdersToHtml(placeholder.placeholder, updatedForm, !!clientFormId, props.enableRichText),
              );
            }, 10);
          }}
        />
      )}
    </div>
  );
};

export default InputWithDynamicData;
