import IconRender from '../icon/IconRender';
import { FCWithChildren } from '../../../types/FCWithChildren';

const EmptyState: FCWithChildren<{ title: string; description: string; icon: string }> = (props) => {
  const { title, description, icon, children } = props;
  return (
    <div data-cy="search-actions-empty" className="flex h-full flex-col items-center justify-center gap-4">
      <IconRender type={icon} className="bg-background-1 mt-5 h-20 w-20 rounded-full p-4" />
      <div className="font-medium">{title}</div>
      <div className="text-dpm-12">{description}</div>
      {children}
    </div>
  );
};

export default EmptyState;
