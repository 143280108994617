import { FC } from 'react';
import { ActionPlaceholderData } from '../../../models/Form';
import Loader from '../Loader';
import { actionDataInterpolationReplacer } from '../../../utils/interpolation/ActionDataInterpolator';

export const AnswerPlaceholderWord: FC<{ placeholderId: string }> = (props) => {
  const { placeholderId: id } = props;

  return (
    <span
      className="bg-gray-4 text-dpm-14 inline cursor-default select-none rounded-lg px-2 py-1 align-middle font-normal leading-[21px] text-black opacity-100 outline-none"
      contentEditable={false}
      data-placeholder={id}
      data-is-answer={true}
    >
      <span data-loader="true">
        <Loader centered={false} size={4} style={{ display: 'inline-block', marginBottom: '-0.25rem' }} />
      </span>
      {/* Make sure to keep content inside the span, because if it becomes empty,
          ie. the loader gets removed, then the entire element gets removed, 
          before the placeholder content can be rendered */}
      <span className="hidden">&nbsp;</span>
    </span>
  );
};

export const answerPlaceholderWordInterpolator = (
  placeholder: string,
  placeholderData: ActionPlaceholderData,
  key: number,
  otherPlaceholders: Record<string, ActionPlaceholderData>,
) => {
  return actionDataInterpolationReplacer(placeholder, placeholderData && { ...placeholderData, dataFormat: 'comma' }, key, otherPlaceholders);
};
