import { FormBuilderForm } from '../components/form-builder/FormBuilderTypes';
import { AnswerPlaceholderWord } from '../components/shared/placeholder/AnswerPlaceholderWord';
import PlaceholderWord from '../components/shared/placeholder/PlaceholderWord';
import ReactDOMServer from 'react-dom/server';
import { sanitizeHTML } from './RichTextUtils';

const placeholderRegex = /\${{(\S+?)}}/g;

export const parsePlaceholdersToHtml = (text: string, form: FormBuilderForm, isAnswerPlaceholder = false, allowFullHtml = false): string => {
  if (!text) return text;
  if (!text.includes('${{')) return text;

  const result = text
    // remove extra spaces around placeholders, we will add them back below
    .replace(/\s+(\${{(\S+?)}})\s+/g, '$1')
    .replace(placeholderRegex, (_, key) => {
      const friendlyName = form.placeholders?.find((x) => x.placeholder === '${{' + key + '}}')?.friendlyName;
      const component = !isAnswerPlaceholder ? (
        <PlaceholderWord placeholderId={key} friendlyText={friendlyName ?? '?'} form={form} />
      ) : (
        <AnswerPlaceholderWord placeholderId={key} />
      );
      const word = ReactDOMServer.renderToStaticMarkup(component)
        // Replace non-breaking spaces with HTML entities
        .replaceAll('\u00A0', '&nbsp;')
        .trim();

      return ` ${word} `;
    });

  return sanitizeHTML(result, true, allowFullHtml);
};

const domParser = new DOMParser();

export const parseHtmlToPlaceholders = (text: string): string => {
  const html = domParser.parseFromString(text, 'text/html');
  const placeholders = html.querySelectorAll('[data-placeholder]');
  placeholders.forEach((placeholder) => {
    const key = placeholder.getAttribute('data-placeholder');

    // Trim spaces from previous and next text nodes, otherwise they "pile up"
    const prev = placeholder.previousSibling;
    if (prev?.nodeType === Node.TEXT_NODE) {
      prev.textContent = (prev.textContent?.replace(/&nbsp;$/, '').trim() ?? '') + ' ';
    }

    const next = placeholder.nextSibling;
    if (next?.nodeType === Node.TEXT_NODE) {
      next.textContent = ' ' + (next.textContent?.replace(/^&nbsp;/, '').trim() ?? '');
    }

    placeholder.outerHTML = `\${{${key}}}`;
  });

  return (
    html.body.innerHTML
      // Replace common HTML entities with their characters
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&amp;', '&')
      .replaceAll('&quot;', '"')
      .replaceAll('&apos;', "'")
  );
};
