import { FC, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormConfig, PlaceholderFormat } from '../../../models/Form';
import { FCWithChildren } from '../../../types/FCWithChildren';
import StringUtils from '../../../utils/StringUtils';
import { Action, FieldLabelLengthLimit, FormBuilderForm, FormBuilderPlaceholder, Section } from '../../form-builder/FormBuilderTypes';
import { SupportedLanguage } from '../../../types/Languages';
import ActionTypes from '../../form/ActionTypes';
import Button, { ButtonSize, ButtonType } from '../form-control/Button';
import { Item } from '../form-control/DropdownDefaultComponents';
import DropdownSelect from '../form-control/DropdownSelect';
import { Input, InputStyle } from '../form-control/Input';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import ExternalDynamicDataIcon from '../icon/ExternalDynamicDataIcon';
import InternalDynamicDataIcon from '../icon/InternalDynamicDataIcon';
import { v4 as uuid } from 'uuid';
import LanguageUtils from '../../../utils/LanguageUtils';
import SearchIcon from '../icon/SearchIcon';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';
import XIcon from '../icon/XIcon';
import { FormType } from '../../../models/FormTypes';
import Tooltip from '../Tooltip';
import EmptyState from './EmptyState';
import InfoIcon from '../icon/InfoIcon';
import HelpCircleIcon from '../icon/HelpCircleIcon';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';
import { MenuAction } from './PlaceholderSelectMenu';
import { plainText } from '../../../utils/RichTextUtils';

type MenuPageProps = {
  previewLanguage: SupportedLanguage;
  form: FormBuilderForm;
  referencedForms: Record<string, FormConfig>;
  menuAction: MenuAction;
  closeMenu: () => void;
  insertPlaceholder: (placeholder: FormBuilderPlaceholder) => void;
  hasPrevious?: boolean;
  allowExternalData?: boolean;
  goPrevious: () => void;
  goNext: (page: ReactElement) => void;
  secondaryDataSourceAction?: Action;
  editingPlaceholderId?: string;
};

export type BasePlaceholderSelectMenuPageProps = MenuPageProps & {
  title: string;
  footer?: ReactNode;
  searchEnabled?: boolean;
};

const BasePlaceholderSelectMenuPage: FCWithChildren<BasePlaceholderSelectMenuPageProps> = (props) => {
  const { title, footer, hasPrevious, children, goPrevious, form, searchEnabled } = props;
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (searchEnabled && searchRef.current) {
        searchRef.current.focus();
      }
    }, 500);
  }, [searchEnabled]);

  const { t } = useTranslation('form-builder');

  const [search, setSearch] = useState('');

  const internalTitle = useMemo(() => (form.placeholders ? interpolateActionData(title, form.placeholders) : title), [form.placeholders, title]);

  return (
    <div className="flex max-h-[400px] min-h-[300px] flex-col">
      <Tooltip text={internalTitle} truncatedTextMode>
        {(tooltip) => (
          <div className="bg-primary-1 min-w-full max-w-0 flex-shrink-0 select-none truncate p-2 text-white" {...tooltip}>
            {hasPrevious && <ChevronIcon onClick={goPrevious} type={ChevronType.LEFT} className="mr-1 h-6 w-6" />}
            {internalTitle}
          </div>
        )}
      </Tooltip>

      {/* content editable because we don't have a small enough input */}
      {searchEnabled && (
        <div className="bg-gray-5 flex flex-shrink-0 items-center gap-2 p-1">
          <SearchIcon className="h-5 w-5 flex-shrink-0" />
          <Input
            placeholder={t('placeholder-menu.search')}
            borderColor="border-transparent"
            value={search}
            style={InputStyle.PLAIN}
            onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
            innerRef={searchRef}
          />
          {search && <XIcon onClick={() => setSearch('')} className="h-5 w-5 flex-shrink-0 cursor-pointer" />}
        </div>
      )}

      <div className="flex-grow overflow-auto">{search ? <SearchPlaceholderMenu search={search} {...props} /> : children}</div>

      <div className="flex-shrink-0 border-t p-2 empty:hidden">{footer}</div>
    </div>
  );
};

type SearchPlaceholdeProps = BasePlaceholderSelectMenuPageProps & {
  search: string;
};

const SearchPlaceholderMenu: FC<SearchPlaceholdeProps> = (props) => {
  const { form, search, goNext, allowExternalData = true } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('form-builder');
  const { clientFormId } = useFormRendererInfo();

  useEffect(() => {
    setIsLoading(true);
    const timeOut = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [search]);

  const results = useMemo(() => {
    return form.sections
      .map((s) => ({
        ...s,
        actions: s.actions.filter(
          (a) =>
            ActionTypes[a.type || '']?.dynamicData?.enabled &&
            (ActionTypes[a.type].actionTitle(a, props.previewLanguage).toLocaleLowerCase().indexOf(search) > -1 ||
              LanguageUtils.getTranslation('fieldLabel', a.translations || {}, props.previewLanguage)
                .toLocaleLowerCase()
                .indexOf(search) > -1),
        ),
      }))
      .filter((x) => (clientFormId ? x.visible && x.actions.length > 0 && !x.actions.every((a) => !a.visible) && !x.isDraft : true))
      .filter((x) => x.actions.length);
  }, [clientFormId, form.sections, props.previewLanguage, search]);

  const next = useCallback(
    (action: Action) => {
      goNext(
        allowExternalData && ActionTypes[action.type || '']?.dynamicData?.canHaveSecondaryData ? (
          <PrimarySecondarySelectPlaceholderMenu {...props} action={action} hasPrevious />
        ) : (
          <ActionPropertiesPlaceholderMenu {...props} action={action} hasPrevious />
        ),
      );
    },
    [allowExternalData, goNext, props],
  );

  return (
    <div className="p-2">
      <SkeletonLoader ready={!isLoading} type="rowDetail" rows={5}>
        {results.map((s) => (
          <div key={s.id}>
            <div className="text-dpm-14 text-gray-2 p-2 font-medium">
              {LanguageUtils.getTranslation('title', s.translations, props.previewLanguage)}
            </div>
            {s.actions.map((a) => (
              <ActionListItem key={a.id} form={form} action={a} onClick={() => next(a)} previewLanguage={props.previewLanguage} />
            ))}
          </div>
        ))}
      </SkeletonLoader>
      {results.length === 0 && (
        <EmptyState
          icon="SearchIcon"
          title={t('placeholder-menu.search-empty.title')}
          description={t('placeholder-menu.search-empty.description', { phrase: search })}
        />
      )}
    </div>
  );
};

export const SelectSectionPlaceholderMenu: FC<MenuPageProps> = (props) => {
  const { form, goNext, allowExternalData = true, hasPrevious } = props;
  const { t } = useTranslation('form-builder');
  const { clientFormId } = useFormRendererInfo();

  const next = useCallback(
    (section: Section) => {
      goNext(<SelectActionPlaceholderMenu {...props} section={section} hasPrevious />);
    },
    [goNext, props],
  );

  return (
    <BasePlaceholderSelectMenuPage
      {...props}
      title={t('placeholder-menu.pick-section.title')}
      searchEnabled
      footer={
        <span className="text-gray-3 italic">
          {allowExternalData
            ? form.type === FormType.Resource
              ? t('placeholder-menu.position-hint.external-resource')
              : t('placeholder-menu.position-hint.external')
            : t('placeholder-menu.position-hint.internal')}
        </span>
      }
      hasPrevious={hasPrevious}
    >
      {form?.sections
        .filter((x) => (clientFormId ? x.visible && x.actions.length > 0 && !x.actions.every((a) => !a.visible) && !x.isDraft : true))
        .map((x) => (
          <div key={x.id} className="hover:bg-gray-5 text-dpm-16 flex cursor-pointer justify-between border-b px-4 py-2" onClick={() => next(x)}>
            <Tooltip text={LanguageUtils.getTranslation('title', x.translations, props.previewLanguage)} truncatedTextMode>
              {(tooltip) => (
                <div className="truncate" {...tooltip}>
                  {LanguageUtils.getTranslation('title', x.translations, props.previewLanguage)}
                </div>
              )}
            </Tooltip>
            <div>
              <ChevronIcon type={ChevronType.RIGHT} className="h-4 w-4" />
            </div>
          </div>
        ))}
      {!form?.sections.length && (
        <EmptyState icon="InfoIcon" title={t('placeholder-menu.section-empty.title')} description={t('placeholder-menu.section-empty.description')} />
      )}
    </BasePlaceholderSelectMenuPage>
  );
};

type ActionMenuPageProps = MenuPageProps & {
  section: Section;
};

const SelectActionPlaceholderMenu: FC<ActionMenuPageProps> = (props) => {
  const { section, allowExternalData = true, goNext, menuAction, form } = props;
  const { t } = useTranslation('form-builder');

  const next = useCallback(
    (action: Action) => {
      goNext(
        allowExternalData && ActionTypes[action.type || '']?.dynamicData?.canHaveSecondaryData ? (
          <PrimarySecondarySelectPlaceholderMenu {...props} action={action} />
        ) : (
          <ActionPropertiesPlaceholderMenu {...props} action={action} />
        ),
      );
    },
    [allowExternalData, goNext, props],
  );

  const availableActions = useMemo(
    () => section.actions.filter((x) => ActionTypes[x.type || '']?.dynamicData?.enabled && x.id !== menuAction.targetId),
    [menuAction.targetId, section.actions],
  );

  return (
    <BasePlaceholderSelectMenuPage
      {...props}
      title={LanguageUtils.getTranslation('title', section.translations, props.previewLanguage)}
      searchEnabled
      footer={
        <span className="text-gray-3 italic">
          {allowExternalData ? t('placeholder-menu.position-hint.external') : t('placeholder-menu.position-hint.internal')}
        </span>
      }
    >
      {availableActions.map((x) => (
        <ActionListItem key={x.id} form={form} action={x} onClick={() => next(x)} previewLanguage={props.previewLanguage} />
      ))}
      {!availableActions.length && (
        <EmptyState icon="InfoIcon" title={t('placeholder-menu.actions-empty.title')} description={t('placeholder-menu.actions-empty.description')} />
      )}
    </BasePlaceholderSelectMenuPage>
  );
};

type ActionPropertiesMenuPageProps = MenuPageProps & {
  action: Action;
  editingPlaceholderId?: string;
};

const PrimarySecondarySelectPlaceholderMenu: FC<ActionPropertiesMenuPageProps> = (props) => {
  const { action, goNext, referencedForms } = props;
  const { t } = useTranslation('form-builder');

  const next = useCallback(
    (isSecondarySelection: boolean) => {
      if (isSecondarySelection) {
        let targetForm = '';
        if (action.type === 'ChildFormListAction') {
          targetForm = action.data.templateFormId;
        } else if (action.type === 'ResourcePicklistAction') {
          targetForm = action.data.lockedTemplateId;
        } else if (action.type === 'PreSelectedForm') {
          targetForm = action.data.templateFormId;
        }

        if (!targetForm || !referencedForms[targetForm]) {
          return;
        }

        const form = referencedForms[targetForm] as FormBuilderForm;
        goNext(<SelectSectionPlaceholderMenu {...props} allowExternalData={false} form={form} secondaryDataSourceAction={action} />);
      } else {
        goNext(<ActionPropertiesPlaceholderMenu {...props} />);
      }
    },
    [action, goNext, props, referencedForms],
  );

  return (
    <BasePlaceholderSelectMenuPage {...props} title={ActionTypes[action.type].actionTitle(action, props.previewLanguage)}>
      <div className="hover:bg-gray-5 group flex cursor-pointer gap-4 border-b px-4 py-2" onClick={() => next(false)}>
        <InternalDynamicDataIcon className="h-6 w-6" /> {t('placeholder-menu.pick-level.primary-data')}
      </div>
      <div className="hover:bg-gray-5 group flex cursor-pointer gap-4 border-b px-4 py-2" onClick={() => next(true)}>
        <ExternalDynamicDataIcon className="h-6 w-6" />
        {t('placeholder-menu.pick-level.secondary-data')}
      </div>
    </BasePlaceholderSelectMenuPage>
  );
};

export const ActionPropertiesPlaceholderMenu: FC<ActionPropertiesMenuPageProps> = (props) => {
  const { action, closeMenu, insertPlaceholder, menuAction, secondaryDataSourceAction, editingPlaceholderId, form, referencedForms } = props;
  const { t } = useTranslation('form-builder');

  const [friendlyName, setFriendlyName] = useState(() => {
    if (!editingPlaceholderId) {
      return '';
    }

    return (
      [form, ...(Object.values(referencedForms) as FormBuilderForm[])]
        .flatMap((x) => x.placeholders)
        .find((x) => x?.placeholder === editingPlaceholderId)?.friendlyName ?? ''
    );
  });

  const formatOptions = useMemo<Item[]>(() => {
    return (ActionTypes[secondaryDataSourceAction?.type || action?.type || '']?.dynamicData?.formatOptions || []).filter(Boolean).map((x) => ({
      id: x as NonNullable<PlaceholderFormat>,
      text: t(`placeholder-menu.format.${x as NonNullable<PlaceholderFormat>}`),
      value: x as NonNullable<PlaceholderFormat>,
    }));
  }, [action, secondaryDataSourceAction, t]);

  const [format, setFormat] = useState<Item | null>(() => {
    if (!editingPlaceholderId) {
      return formatOptions[0] || null;
    }

    const format = [form, ...(Object.values(referencedForms) as FormBuilderForm[])]
      .flatMap((x) => x.placeholders)
      .find((x) => x?.placeholder === editingPlaceholderId)?.dataFormat;

    return format
      ? {
          id: format,
          text: t(`placeholder-menu.format.${format}`),
          value: format,
        }
      : formatOptions[0] || null;
  });

  const fieldLabel = useMemo(
    () =>
      action.data?.fieldLabel ||
      plainText(
        StringUtils.toLowerDashed(
          LanguageUtils.getTranslation('title', action.translations || {}, props.previewLanguage) ||
            LanguageUtils.getTranslation('question', action.translations || {}, props.previewLanguage),
          FieldLabelLengthLimit,
        ),
      ),
    [action.data?.fieldLabel, action.translations, props.previewLanguage],
  );

  const done = useCallback(() => {
    insertPlaceholder({
      targetId: menuAction.targetId,
      target: menuAction.target,
      dataFormat: (format?.id || null) as PlaceholderFormat,
      friendlyName: friendlyName || fieldLabel,
      translations: {}, // TODO
      referencedActionId: action.id,
      actionIdContainingAssociation: secondaryDataSourceAction?.id || null,
      placeholder: editingPlaceholderId || `$\{{${uuid()}}}`,
    });
  }, [
    action.id,
    editingPlaceholderId,
    fieldLabel,
    format?.id,
    friendlyName,
    insertPlaceholder,
    menuAction.target,
    menuAction.targetId,
    secondaryDataSourceAction?.id,
  ]);

  const actionTitle = useMemo(() => plainText(ActionTypes[action.type].actionTitle(action, props.previewLanguage)), [action, props.previewLanguage]);

  const title = useMemo(
    () => (form.placeholders ? interpolateActionData(actionTitle, form.placeholders) : actionTitle),
    [actionTitle, form.placeholders],
  );

  return (
    <BasePlaceholderSelectMenuPage
      {...props}
      title={actionTitle}
      footer={
        <div className="flex justify-end gap-4">
          <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={closeMenu}>
            {t('placeholder-menu.finalise.cancel')}
          </Button>
          <Button size={ButtonSize.S} type={ButtonType.PRIMARY} onClick={done}>
            {t('placeholder-menu.finalise.done')}
          </Button>
        </div>
      }
    >
      <div className="flex min-h-full flex-col">
        <div className="flex flex-shrink-0 items-end justify-between border-b px-4 py-2">
          <div className="w-4/5">
            <Tooltip text={title} truncatedTextMode>
              {(tooltip) => (
                <div className="text-dpm-16 min-w-full max-w-0 truncate font-medium" {...tooltip}>
                  {title}
                </div>
              )}
            </Tooltip>
            <div className="text-dpm-14">{fieldLabel}</div>
          </div>
          <div className="flex w-1/5 justify-end">
            <div className="bg-gray-1 text-dpm-12 flex-shrink-0 rounded-full px-3 py-1 italic text-white">
              {t(`placeholder-menu.action-label.${action.type}`) as string}
            </div>
          </div>
        </div>
        <div className="flex-grow px-4">
          <div className="relative flex justify-end">
            {action.visible !== 'true' && (
              <Tooltip text={t('placeholder-menu.friendly-name.conditional-invisible')}>
                {(tooltip) => (
                  <div {...tooltip} className="absolute right-6 top-2">
                    <InfoIcon className="text-semantic-2 h-5 w-5" />
                  </div>
                )}
              </Tooltip>
            )}
            <Tooltip text={t('placeholder-menu.friendly-name.tooltip')}>
              {(tooltip) => (
                <div {...tooltip} className="absolute top-2">
                  <HelpCircleIcon className="h-5 w-5" />
                </div>
              )}
            </Tooltip>
          </div>
          <Input
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
            placeholder={fieldLabel}
            label={t('placeholder-menu.friendly-name.title')}
          />

          {formatOptions.length > 0 && (
            <DropdownSelect
              value={format}
              options={formatOptions}
              onChange={setFormat}
              label={t('placeholder-menu.format.title')}
              placeholder={t('placeholder-menu.format.title')}
            />
          )}
        </div>
      </div>
    </BasePlaceholderSelectMenuPage>
  );
};

type ListItemProps = {
  form: FormBuilderForm;
  action: Action;
  onClick: () => void;
  previewLanguage: SupportedLanguage;
};

const ActionListItem: FC<ListItemProps> = (props) => {
  const { form, action, onClick } = props;
  const { t } = useTranslation('form-builder');
  const title = useMemo(() => {
    const question = plainText(ActionTypes[action.type].actionTitle(action, props.previewLanguage));
    return form.placeholders ? interpolateActionData(question, form.placeholders) : question;
  }, [action, form.placeholders, props.previewLanguage]);

  return (
    <div className="hover:bg-gray-5 group flex cursor-pointer items-end justify-between border-b px-4 py-2" onClick={onClick}>
      <div className="w-4/5">
        <Tooltip text={title} truncatedTextMode>
          {(tooltip) => (
            <div className="text-dpm-16 min-w-full max-w-0 truncate font-medium" {...tooltip}>
              {title}
            </div>
          )}
        </Tooltip>
        <div className="text-dpm-14">
          {action.data?.fieldLabel ||
            plainText(
              StringUtils.toLowerDashed(
                LanguageUtils.getTranslation('title', action.translations || {}, props.previewLanguage) ||
                  LanguageUtils.getTranslation('question', action.translations || {}, props.previewLanguage),
                FieldLabelLengthLimit,
              ),
            )}
        </div>
      </div>
      <div className="flex w-1/5 justify-end">
        <div className="bg-gray-1 text-dpm-12 flex-shrink-0 rounded-full px-3 py-1 italic text-white opacity-50 group-hover:opacity-100">
          {t(`placeholder-menu.action-label.${action.type}`)}
        </div>
      </div>
    </div>
  );
};
