import { FC, useMemo } from 'react';
import InternalDynamicDataIcon from '../icon/InternalDynamicDataIcon';
import XIcon from '../icon/XIcon';
import { FormBuilderForm } from '../../form-builder/FormBuilderTypes';
import ExternalDynamicDataIcon from '../icon/ExternalDynamicDataIcon';

export type PlaceholderWordProps = {
  placeholderId: string;
  friendlyText: string;
  form: FormBuilderForm;
};

const PlaceholderWord: FC<PlaceholderWordProps> = (props) => {
  const { placeholderId, friendlyText, form } = props;

  const ownFormActions = useMemo(() => form?.sections.flatMap((x) => x.actions) || [], [form?.sections]);

  const isSecondaryData = useMemo(() => {
    const placeholderObj = form?.placeholders?.find((x) => x.placeholder === '${{' + placeholderId + '}}');
    return !ownFormActions.find((x) => x.id === placeholderObj?.referencedActionId);
  }, [form?.placeholders, placeholderId, ownFormActions]);

  return (
    <span
      className="bg-primary-1 text-dpm-14 inline-flex cursor-default items-center whitespace-nowrap rounded-2xl px-2 align-middle font-normal leading-[21px] text-white opacity-100 outline-none"
      contentEditable={false}
      data-placeholder={placeholderId}
    >
      <span className="mr-1 flex w-5 items-center">
        {!isSecondaryData && <InternalDynamicDataIcon className="inline h-4 w-4 text-white" />}
        {isSecondaryData && <ExternalDynamicDataIcon className="inline h-4 w-4 text-white" />}
      </span>
      {friendlyText}
      <span data-placeholder-delete={placeholderId} className="ml-1 flex cursor-pointer items-center">
        <XIcon className="h-4 w-4 text-white" />
      </span>
    </span>
  );
};

export default PlaceholderWord;
